// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "@fortawesome/fontawesome-pro/js/regular.min" 
import "@fortawesome/fontawesome-pro/js/light.min" 
import "@fortawesome/fontawesome-pro/js/brands.min" 
import "@fortawesome/fontawesome-pro/js/fontawesome.min"
import * as bootstrap from "bootstrap"
import iFrameResize from "iframe-resizer/js/iframeResizer.js"

import "./controllers/application_controller"
import "./controllers/accordion_controller" 
import "./controllers/navigation_controller" 
import "./controllers/mobile_navigation_controller" 
import "./controllers/search_controller" 
import "./controllers/media_controller"
import "./controllers/lazyloading_controller"

import "./stimulus_controllers/index.js"
